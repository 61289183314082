<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getPrinterApi } from '@/api/setting/printer'



/**
 * User list component
 */
export default {
  page: {
    title: "Printer Management",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Printer Management",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "Printer",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "PrinterName",
          sortable: true,
        },
        {
          key: "PrinterNumber",
          sortable: true,
        },
        {
          key: "Office",
          sortable: true,
        },
        {
          key: "Status",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
      submitted: false,

      cheque: {
        start: "",
        stop: "",
      },
      searchKey: "",
      accounts: [],
      bank_choice: {}
    };
  },

  validations: {
    cheque: {
      start: { required },
      stop: { required },
    },
  },

  components: {
    Layout,
    PageHeader,
    
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {

  },
  methods: {

    queryList() {
     
      getPrinterApi().printer_list({}).then((res) => {
        this.listingData = []
        res.data.map((o) => {

          this.listingData.push(o)
          
        })
        this.totalRows = this.listingData.length

      })
    },

    test_print(printer) {

      getPrinterApi().print_test_page({printer_id : printer.id}).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Test Page Sent Job Id : "+res.data.job_id);
        } else {
          this.$alertify.error("Test Failed "+res.errCode);
        }
      })
    },

   

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Printer
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">

                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">

                  <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <!-- <b-dropdown-item v-b-modal.cheque-dialog>Add Cheque</b-dropdown-item> -->
                  </b-dropdown>
                  <!-- end dropdown -->
                  <!-- <b-modal id="cheque-dialog" title="Add Cheque" size="lg" centered hide-footer>
                    <SetupChequeDialog @cancel="$bvModal.hide('cheque-dialog')" @confirm="setup_cheque" />
                  </b-modal> -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="listingData" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">

                  <template #cell(PrinterName)="data">
                    {{ data.item.printer_name }}
                  </template>
                  <template #cell(PrinterNumber)="data">
                    {{ data.item.printer_id }}
                  </template>
                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(Office)="data">
                    {{ data.item.location }}
                  </template>

                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                       <b-dropdown-item @click="disable_printer(data.item)">Disable</b-dropdown-item> 
                       <b-dropdown-item @click="test_print(data.item)">Print Test Page</b-dropdown-item> 
                       
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
