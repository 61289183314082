
import request from '@/utils/request';



class PrinterApi {


    printer_list = (data) => {
        data.evt_type = 0
        return request({
            url: '/adm/printer/list',
            method: 'post',
            data
        });	
    }

    print_test_page = (data) => {
        return request({
            url: '/adm/printer/print_test_page',
            method: 'post',
            data
        });	
    }
    


}




let _printer_api = null

const getPrinterApi = () => {
    if (!_printer_api) {
        _printer_api = new PrinterApi();
    }
    return _printer_api;
}

export { getPrinterApi };


